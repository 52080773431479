"use strict";

(function () {
  var playButtons = document.getElementsByClassName('js-play-button');
  if (playButtons) {
    Array.from(playButtons).forEach(function (playButton) {
      var container = playButton.parentElement;
      var youtubeLink = playButton.dataset.youtubelink;
      var youtubeTitle = playButton.dataset.youtubetitle;
      playButton.addEventListener('click', function () {
        container.innerHTML = '';
        var videoPlayer = document.createElement('div');
        videoPlayer.setAttribute('class', 'm-media__youtube');
        var iFrame = document.createElement('IFRAME');
        iFrame.setAttribute('src', "https://www.youtube.com/embed/".concat(youtubeLink, "?autohide=0&color=white&controls=1&modestbranding=1&showinfo=0&rel=0&autoplay=1"));
        iFrame.setAttribute('frameBorder', '0');
        iFrame.setAttribute('allowfullscreen', '1');
        iFrame.setAttribute('title', youtubeTitle);
        iFrame.setAttribute('class', 'm-media__iframe');
        videoPlayer.appendChild(iFrame);
        container.appendChild(videoPlayer);
      });
    });
  }
})();