import 'xp-lib-brand/index'; // import xp-lib-brand js first

// additional code
import { init as initDeviationForm } from './deviation-form';

if (document.readyState !== 'loading') {
	initJavaScript();
} else {
	document.addEventListener('DOMContentLoaded', initJavaScript);
}

function initJavaScript() {
	document.querySelectorAll('.deviation-form').forEach(initDeviationForm);
}
