"use strict";

function isIE1011() {
  return /Trident\/|MSIE/.test(window.navigator.userAgent);
}
var showBrowserAlert = function showBrowserAlert() {
  var unsupportedBrowserModals = document.getElementsByClassName('unsupported-browser');
  if (unsupportedBrowserModals && unsupportedBrowserModals[0]) {
    var unsupportedBrowserModal = unsupportedBrowserModals[0];
    var isIE11 = isIE1011();
    if (isIE11) {
      unsupportedBrowserModal.innerHTML = 'NB! tine.no vises ikke optimalt fordi nettleseren din er gammel - bytt nettleser nå! Internet Explorer er i ferd med å bli utdatert, både funksjonsmessig og sikkerhetsmessig Vi anbefaler å bytte til <a class="chrome" href="https://www.google.com/intl/no/chrome/">Google Chrome</a><a href="#" class="closeunsupported">X</a>';
      unsupportedBrowserModal.style.display = 'block';
    }
    var closeUnsupporteds = document.getElementsByClassName('closeunsupported');
    if (closeUnsupporteds && closeUnsupporteds[0]) {
      var closeUnsupported = closeUnsupporteds[0];
    }
    document.addEventListener('click', function (event) {
      var target = event.target;
      if (target == closeUnsupported) {
        unsupportedBrowserModal.style.display = 'none';
      }
    });
  }
};
document.addEventListener('DOMContentLoaded', showBrowserAlert);