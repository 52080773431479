'use strict';

Array.prototype.slice.call(document.querySelectorAll('.a-accordion')).forEach(function (accordion) {
  var triggers = Array.prototype.slice.call(accordion.querySelectorAll('.a-accordion__trigger'));
  var accordionButtonIcon = accordion.querySelector('.a-icon');
  accordion.addEventListener('click', function (event) {
    var target = event.target;
    if (target.classList.contains('a-accordion__trigger')) {
      accordionButtonIcon.classList.toggle('a-icon-arrow_down');
      accordionButtonIcon.classList.toggle('a-icon-arrow_up');
      accordion.classList.toggle('a-accordion--true');
      target.classList.toggle('a-accordion__trigger--true');
      var accordionInnerContent = document.getElementById(target.getAttribute('aria-controls'));
      if (!(target.getAttribute('aria-expanded') === 'true')) {
        target.setAttribute('aria-expanded', 'true');
        accordionInnerContent.removeAttribute('hidden');
      } else {
        target.setAttribute('aria-expanded', 'false');
        accordionInnerContent.setAttribute('hidden', '');
      }
      event.preventDefault();
    }
  });
  accordion.addEventListener('keydown', function (event) {
    var target = event.target;
    var key = event.which.toString();
    var ctrlModifier = event.ctrlKey && key.match(/33|34/);
    if (target.classList.contains('a-accordion__trigger')) {
      if (key.match(/38|40/) || ctrlModifier) {
        var index = triggers.indexOf(target);
        var direction = key.match(/34|40/) ? 1 : -1;
        var length = triggers.length;
        var newIndex = (index + length + direction) % length;
        triggers[newIndex].focus();
        event.preventDefault();
      } else if (key.match(/35|36/)) {
        switch (key) {
          case '36':
            triggers[0].focus();
            break;
          case '35':
            triggers[triggers.length - 1].focus();
            break;
        }
        event.preventDefault();
      }
    }
  });
});