"use strict";

document.addEventListener("click", function (e) {
  var target = e.target.closest(".o-search-page--next-page");
  var targeturl = e.target.getAttribute('href');
  if (target && targeturl.includes('sok/')) {
    e.preventDefault();
    var request = new XMLHttpRequest();
    request.open('GET', e.target.getAttribute('href'), true);
    request.onload = function () {
      if (request.status >= 200 && request.status < 400) {
        e.target.outerHTML = request.responseText;
      }
    };
    request.send();
  }
});